<template>
  <dialog-edit :value="value" @input="$emit('input')" class="ma-4">
    <template v-slot:title>
      <br />
    </template>
    <div v-if="!loading" class="pa-3" style="text-align: initial" v-html="content"></div>
    <a-loader v-if="loading" />
    <template v-if="!loading" v-slot:actions>
      <v-btn
        @click="
          $emit('agree');
          $emit('input');
        "
        >Согласен</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn @click="$emit('input')">Закрыть</v-btn>
    </template>
  </dialog-edit>
</template>

<script>
export default {
  props: {
    value: Boolean,
    content: String,
    id: Number,
  },
  data() {
    return {
      data: {},
      loading: false,
    };
  },
  computed: {},
  created() {},
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
